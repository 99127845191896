import { useAuth } from "~/stores/auth.js";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.path.startsWith("/setup")) {
    return;
  }

  try {
    await useAuth().initialize();
  } catch (error) {
    return navigateTo("/setup");
  }
});
